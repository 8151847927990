.luxBusinesses {
  display: flex;
  justify-content: space-between;
}
.business {
  display: flex;
  flex: wrap;
  align-items: center;
  width: 100%;
  background-color: #fdf7d9;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.business:hover {
  transform: scale(1.05);
}

.business span {
  font-weight: 500;
  font-size: 13px;
  margin: 5px;
}
.business_image {
  height: 2.5rem;
  width: 2.5rem;
  margin: 5px;
}
@media (max-width: 378px) {
  .off {
    display: none;
  }

  .luxBusinesses {
    flex-wrap: wrap;
    max-width: 350px;
    margin: auto auto;
    margin-left: 1.2rem;
  }
  .luxBusinesses > * {
    flex: 1;
  }
  .category_icons {
    height: 10px;
    width: 1px;
  }
  .category_items {
    display: flex;
    margin: 5px;
  }
}
@media (max-width: 468px) {
  .luxBusinesses {
    flex-wrap: wrap;
    max-width: 350px;
    margin: auto auto;
    margin-left: 1.2rem;
  }
  .luxBusinesses > * {
    flex: 1 1 30%;
  }
}
