/* src/Styles/WhatsAppFloatingButton.css */

.float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px; /* Distance from the bottom */
    right: 40px; /* Distance from the right */
    background-color: #25d366; /* WhatsApp green color */
    color: #FFF;
    border-radius: 50%; /* Circle shape */
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999; /* Shadow effect */
    z-index: 100; /* On top of other elements */
}

.my-float {
    margin-top: 16px; /* Center the icon */
}
