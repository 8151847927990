.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-height: 30rem; /* Adjusted from 18rem */
  min-width: 100px;
  margin: 3px;
  padding: 30px;
  width: 50%;
  background-color: #fdf7d9;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.product:hover {
  transform: scale(1.03);
}
.product_info {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 15px;
  font-size: small;
}
.title {
  height: auto;
  overflow: hidden;
  margin-bottom: 0px;
  color: black;
}
.product_price {
  margin-top: 0px;
  color:black;
}
.product_price span {
  font-weight: 10;
}
.product_rating {
  display: flex;
}
.product_rating img {
  height: 0.9rem;
}

.product img {
  display: flex;
  /*height: 150px; /* Change as needed */
  width: 100%; /* Ensure it takes full width */
  object-fit: contain; /* Use contain to keep aspect ratio */
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
}

.button {
  height: 1rem;
  width: 100%;
  margin: 0px 0px 5px 0px;
}

.click_button {
  width: 100%;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
  padding: 5px;
  text-align: center;
  color: #fdf7d9;
  background-color: #d5a021;
  border-color: #d5a021 #d5a021 #d5a021;
  display: none;
}
.product:hover .click_button {
  display: block;
  cursor: pointer;
}

.click_button:active {
  cursor: pointer;
  color: #e4811c;
  background: white;
  border-color: #a88734 #f0c14b #f0c14b;
}