.header {
    background-color: #fdf7d9;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
    max-width: 1500px;
    margin: auto auto;
  }
  
  .header_nav {
    display: flex;
    justify-content: space-evenly;
  }
  .header_link {
    color: #483c32;
    text-decoration: none;
  }
  .header_logo {
    width: 100px;
    object-fit: contain;
    margin: 10px 40px 10px 40px;
  }
  .header_search {
    display: flex;
    flex: 1;
    border: 1px solid #483c32;
    border-radius: 5px;
  }
  .header_search input:focus {
    outline: none;
  }
  .header_searchIcon {
    padding: 5px;
    height: 22px !important;
  }
  .header_searchInput {
    height: 12px;
    padding: 10px;
    border: none;
    width: 100%;
    background-color: #fdf7d9;
  }
  .search_button {
    background-color: #d5a021;
    color: #483c32;
    border: none;
    border-radius: 4px;
    margin-left: 5px;
    height: 2rem;
    font-size: 11px;
    font-weight: bolder;
    box-shadow: 2px 1px 3px 1px #483c32;
  }
  .search_button:hover {
    background-color: #d5a021;
  }
  .header_option {
    display: flex;
    text-align: left;
    margin: 10px 10px;
  }
  .header_optionLineOne {
    font-size: 10px;
  }
  .header_optionLineTwo {
    font-size: 13px;
    font-weight: 600;
    margin-left: 7px;
  }
  .header_optionBasket {
    display: flex;
    align-items: center;
  }
  .header_basketCount {
    margin: 10px;
    margin-right: 40px;
  }
  .header_optionBasket_title {
    font-size: 13px;
    font-weight: 600;
    margin-left: 7px;
    margin-right: 7px;
  }
  @media (max-width: 320px) {
    .header {
      /* width: 420px; */
      /* max-width: 900px; */
      margin: auto auto;
    }
    .header_logo {
      width: 60px;
      margin: 7px;
    }
    .search_button {
      display: none;
    }
    .header_searchIcon {
      height: 20px !important;
    }
    .header_option {
      margin: 10px 2px;
    }
    .header_optionLineOne {
      font-size: 10px;
    }
    .header_optionLineTwo {
      font-size: 12px;
      margin-left: 0px;
    }
    .gone {
      display: none;
    }
    .header_basketCount {
      margin-right: 5px;
    }
    .header_optionBasket_title {
      font-size: 13px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  
  @media (max-width: 680px) {
    .header {
      max-width: 690px;
      margin: auto auto;
    }
    .header_logo {
      width: 80px;
      margin: 7px;
    }
    .search_button {
      display: none;
    }
    .header_searchIcon {
      height: 20px !important;
    }
    .header_option {
      margin: 10px 2px;
    }
    .header_optionLineOne {
      font-size: 10px;
    }
    .header_optionLineTwo {
      font-size: 12px;
      margin-left: 0px;
    }
    .gone {
      display: none;
    }
    .header_basketCount {
      margin-right: 5px;
    }
    .header_optionBasket_title {
      font-size: 13px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  @media (max-width: 250px) {
    .off_cart {
      display: none;
    }
  }