.login_logo {
  margin: 20px 20px;
  width: 100px;
  object-fit: contain;
}
.login {
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login_container {
  width: 300px;
  padding: 20px;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
}
.login_container > form > h5 {
  margin-bottom: 5px;
}
.login_container > form > input {
  margin-bottom: 20px;
  height: 30px;
  width: 98%;
  background-color: #fff;
}
.login_container > p {
  margin-top: 15px;
  font-size: 12px;
}
.login_signButton {
  background-color: #e4811c;
  border-radius: 2px;
  border-color: #a88734;
  margin-top: 10px;
  width: 100%;
  height: 30px;
  font-size: 13px;
  color: #ffffff;
  font-weight: 600;
  border: 1px solid;
}
.login_signButton:hover {
  cursor: pointer;
  color: #e4811c;
  background: white;
  border-color: #a88734;
}
.login_signButton:active {
  cursor: pointer;
  color: #e4811c;
  background: white;
  border-color: #a88734 #f0c14b #f0c14b;
}

.login_registerButton {
  background-color: white;
  border: 1px solid;
  border-radius: 2px;
  border-color: #e4811c;
  margin-top: 10px;
  width: 100%;
  height: 30px;
  color: #e4811c;
}
.login_registerButton:hover {
  cursor: pointer;
  color: white;
  background: #e4811c;
  border-color: #a88734;
}
.login_registerButton:active {
  cursor: pointer;
  color: #e4811c;
  background: white;
  border-color: #a88734 #f0c14b #f0c14b;
}
