.home {
  max-width: 1100px;
  margin: auto auto;
}
.home_row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.home_row > * {
  flex: 1;
}
@media (max-width: 378px) {
  .off {
    display: none;
  }
}
