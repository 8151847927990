/* src/Style/Background.css */
.background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fdf7d9; /* Pale green color */
    z-index: -1; /* Ensure the background stays behind other elements */
  }
  