/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.projcard {
    position: relative;
    width: 100%;
    height: 300px;
    margin-bottom: 40px;
    border-radius: 10px;
    background-color: #fdf7d9;
    border: 2px solid #fdf7d9;
    font-size: 18px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
    transition: box-shadow 0.2s ease, transform 0.2s ease;
}

.projcard:hover {
    box-shadow: 0 34px 32px -33px rgba(0, 0, 0, 0.18);
    transform: translate(0px, -3px);
}

.projcard-innerbox {
    display: flex;
    height: 100%;
}

/* Textbox on the left */
.projcard-textbox {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #483c32;
}

/* Image container on the right */
.projcard-imgbox {
    width: 400px;
    height: 100%;
}

.projcard-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease;
}

.projcard:hover .projcard-img {
    transform: scale(1.05) rotate(1deg);
}

/* Bar Styling */
.projcard-bar {
    width: 50px;
    height: 5px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #d5a021;
    transition: width 0.2s ease;
}

.projcard:hover .projcard-bar {
    width: 70px;
}

.projcard-title {
    font-size: 24px;
    color: #483c32;
}

.projcard-subtitle {
    color: #d5a021;
}

.projcard-description {
    font-size: 15px;
    color: #483c32;
    height: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.projcard-tagbox {
    margin-top: auto;
}

.projcard-tag {
    display: inline-block;
    background: #483c32;
    color: #fdf7d9;
    border-radius: 3px;
    padding: 5px 15px;
    margin-right: 10px;
}
