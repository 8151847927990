/* Header Styles */
.top-header {
    background-color: #483c32;
    color: #fdf7d9;
    padding: 10px 0;
}

.top-header-left {
    font-size: 14px;
}

.top-header-right a {
    color: #fdf7d9;
    margin-left: 15px;
    font-size: 18px;
    text-decoration: none;
}

/* General container styles, shared across components */
.container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
