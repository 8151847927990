
/* Container for max width */
.container {
    max-width: 1100px;
    margin: 0 auto;
}

/* Trip Section */
.trip-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    text-align: center;
}

.trip-container {
    max-width: 1100px;
    width: 100%;
}

/* Subheading */
.subheading {
    font-family: 'Georgia', serif;
    font-size: 18px;
    font-weight: 400;
    color: #d5a021; /* Light golden color */
    margin: 0 0 10px;
    font-style: italic;
}

/* Main Heading */
.main-heading {
    font-size: 36px;
    font-weight: 700;
    color: #483c32; /* Chocolate */
    margin: 0 0 20px;
}

/* Description */
.description {
    font-size: 16px;
    font-weight: 400;
    color: #483c32; /* Chocolate */
    line-height: 1.6;
    margin: 0;
}

/* Search Bar Container */
.search-bar {
    display: flex;
    align-items: flex-start; /* Align items to the start */
    justify-content: space-between;
    width: 100%;
    max-width: 1150px;
    background: #fdf7d9;
    border: 1px solid #d5a021;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 8px;
    margin: 20px auto;
}

/* Individual Option Container */
.search-option {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
}

/* Checkbox group */
.checkbox-group {
    display: flex;
    flex-direction: column;
}

/* Label for options */
.option-label {
    font-size: 14px;
    font-weight: 600;
    color: #483c32;
    margin-bottom: 4px;
}

/* Itinerary Search Bar Container */
.itinerary-search-bar {
    display: flex;
    align-items: flex-start; /* Align items to the start */
    justify-content: space-between;
    width: 100%;
    max-width: 1150px;
    background: #fdf7d9;
    border: 1px solid #d5a021;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 8px;
    margin: 20px auto;
}

/* Input and select styling */
.search-option input,
.search-option select {
    border: none;
    background: #fdf7d9;
    font-size: 16px;
    color: #483c32;
    outline: none;
    padding: 8px 0;
}

/* Button styling */
.guest-controls, .room-controls {
    display: flex;
    flex-direction: column;
}

.guest-controls div,
.room-controls div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px; /* Margin for spacing */
}

.search-option-last {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 23px;
    height: 0px;
}

.search-button {
    display: flex;
    align-items: center;
    background-color: #483c32;
    color: #fdf7d9;
    border: none;
    border-radius: 10px;
    padding: 17px 50px;
    cursor: pointer;
    font-size: 14px;
}

.search-button:hover {
    background-color: #d5a021; /* Darker chocolate on hover */
}

/* Divider */
.divider {
    height: 60px;
    width: 1px;
    background: #d5a021;
    margin: 0;
}



