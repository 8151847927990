
      /* Section Styling */
      .partner-section {
        text-align: center;
        margin: 0px auto;
        padding: 5px;
        background-color: #fdf7d9;
        border-radius: 10px;
        width: 90%;
        max-width: 2000px;
      }
  
      .partner-title {
        font-family: 'Voces', 'Open Sans', arial, sans-serif;
        font-size: 28px;
        color: #483c32;
        margin-bottom: 30px;
      }
  
      /* Partner Container */
      .partner-container {
        display: flex;
        justify-content: space-around;
        gap: 20px;
        flex-wrap: wrap;
      }
  
      /* Partner Card */
      .partner-card {
        position: relative;
        width: 350px;
        height: 200px;
        border-radius: 10px;
        background-color: #fdf7d9;
        border: 2px solid #fdf7d9; /* Gold border */
        box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        overflow: hidden;
        cursor: pointer;
      }
  
      .partner-card:hover {
        transform: translateY(-5px);
        box-shadow: 0 34px 32px -33px rgba(0, 0, 0, 0.18);
      }
  
      /* Gradient Overlay */
      .partner-card::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(45deg, #483c32, transparent);
        opacity: 0.05;
      }
  
      /* Partner Logo */
      .partner-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
  
      .partner-logo img {
        max-height: 80px;
        max-width: 80%;
        transition: transform 0.2s ease;
      }
  
      .partner-card:hover .partner-logo img {
        transform: scale(1.1);
      }
  
      /* Partner Info */
      .partner-info {
        position: absolute;
        bottom: 10px;
        left: 10px;
        color: #483c32;
        font-family: 'Open Sans', arial, sans-serif;
        justify-content: center;
      }
  
      .partner-info h3 {
        font-size: 20px;
        margin-bottom: 5px;
        align-self: center;
      }
  
      .partner-info p {
        font-size: 14px;
        color: #d5a021; /* Gold text for description */
      }