.heading {
  font-size: 1.7rem;
  background-color: #483c32;
  color: #fdf7d9;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading h5 {
  font-weight: 500;
}

.see-all {
  font-size: 1.1rem;
  color: #fdf7d9;
  text-decoration: none;
}

.see-all:hover {
  color: #c16921;
  text-decoration: none;
}
