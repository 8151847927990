/* src/Style/Team.css */

* {
  font-family: Nunito, sans-serif;
}

.text-blk {
  line-height: 25px;
  margin: 0;
}

.responsive-container-block {
  min-height: 75px;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: flex-start;
}

.inner-container {
  max-width: 1320px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  margin: 80px auto 100px;
}

.section-head-text {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  margin-bottom: 18px;
  color: #483c32; /* Dark taupe text */
}

.section-subhead-text {
  color: #483c32; /* Dark taupe text */
  font-size: 20px;
  line-height: 27px;
  max-width: 670px;
  margin-bottom: 80px;
}

.card {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch; /* Ensure all cards stretch to the same height */
  box-shadow: rgba(134, 134, 134, 0.16) 0px 6px 24px;
  border-radius: 10px;
  background-color: #fdf7d9; /* Light taupe background for cards */
  border: 2px solid #fdf7d9; /* Gold border */
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  padding: 20px;
}

.card:hover {
  box-shadow: 0 34px 32px -33px rgba(0, 0, 0, 0.18);
  transform: translate(0px, -3px);
}

.img-wrapper {
  width: 150px; /* Fixed width */
  height: 150px; /* Fixed height */
  flex-shrink: 0; /* Prevent image from shrinking */
  border-radius: 0%; /* Circular image */
  overflow: hidden; /* Ensure the image stays within the circle */
  margin-right: 20px; /* Space between image and content */
}

.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the area without distortion */
}

.name {
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0 5px;
  line-height: 27px;
  color: #483c32; /* Dark taupe text */
}

.position {
  line-height: 26px;
  color: #d5a021; /* Gold accent color */
  font-weight: 700;
  margin-bottom: 10px;
}

.testimonial {
  color: #483c32; /* Dark taupe text */
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.contact-info div {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #483c32; /* Dark taupe text */
}

.contact-info i {
  color: #d5a021; /* Gold accent color */
}

.outer-container {
  background-color: #fdf7d9; /* Light taupe background */
  padding: 0 50px;
}

.container-block {
  min-height: 75px;
  height: fit-content;
  width: 100%;
  padding: 10px;
  display: block;
}

.swiper.team-swiper {
  max-width: 100%;
  width: 100%;
}

.swiper-slide {
  position: static;
  display: flex;
  justify-content: flex-start;
  height: auto; /* Ensure slides take full height */
}

.swiper-wrapper {
  position: static;
  height: fit-content;
}

.swiper-pagination.container-block {
  position: relative;
  margin: 36px 0 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
  height: 12px;
  width: 12px;
  background-color: #d5a021; /* Gold accent color */
}

.card-content {
  text-align: left;
  flex-grow: 1; /* Ensure content takes remaining space */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out content evenly */
}

@media (max-width: 1024px) {
  .swiper-slide {
      justify-content: center;
  }
}

@media (max-width: 768px) {
  .card {
      flex-direction: column;
      align-items: center;
  }

  .card-content {
      text-align: center;
      margin-left: 0;
  }

  .img-wrapper {
      margin-right: 0;
      margin-bottom: 20px;
  }

  .section-head-text {
      font-size: 40px;
  }
}

@media (max-width: 500px) {
  .outer-container {
      padding: 0 20px;
  }

  .section-head-text {
      font-size: 35px;
  }

  .section-subhead-text {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 50px;
  }
}
