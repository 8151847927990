.CheckoutProduct {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
.CheckoutProduct_image {
  object-fit: contain;
  width: 180px;
  height: 180px;
}
.CheckoutProduct_info {
  padding-left: 20px;
}
.CheckoutProduct_title {
  font-size: 17px;
  font-weight: 800;
}
.CheckoutProduct_rating {
  display: flex;
}
.CheckoutProduct_rating img {
  height: 15px;
  object-fit: contain;
  margin-bottom: 5px;
}
.Checkout_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  max-width: 7rem;
  margin: 0px 0px 5px 0px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
  padding: 5px;
  color: aliceblue;
  background-color: #e4811c;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #f0c14b #f0c14b;
}
.Checkout_button:hover {
  cursor: pointer;
  color: #e4811c;
  background: white;
  border-color: #a88734 #f0c14b #f0c14b;
}
.Checkout_button:active {
  cursor: pointer;
  color: white;
  background: #e4811c;
  border-color: #a88734 #f0c14b #f0c14b;
}
