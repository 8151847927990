.error {
  margin-top: 50px;
  margin-bottom: 10px;
  width: 1100px;
  margin: auto auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdf7d9;
  color: #d5a021;
  height: 30vh;
}
