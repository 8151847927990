/* src/Styles/projectRed.css */  
  /* Card Styling */
  .projcard {
    width: 100%;
    height: 300px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: #fdf7d9; /* Light taupe background for cards */
    border: 2px solid #fdf7d9; /* Gold border */
    font-size: 18px;
    overflow: hidden;
    cursor: pointer;

  }
  
  .projcard:hover {
    box-shadow: 0 34px 32px -33px rgba(0, 0, 0, 0.18);
    transform: translate(0px, -3px);
  }
  
  .projcard::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(-70deg, #483c32, transparent 50%);
    opacity: 0.07;
  }
  
  /* Inner Box */
  .projcard-innerbox {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  /* Image Styling */
  .projcard-img {
    position: absolute;
    height: 300px;
    width: 400px;
    top: 0;
    left: 0;
    transition: transform 0.2s ease;
  }
  
  .projcard:hover .projcard-img {
    transform: scale(1.05) rotate(1deg);
  }
  
  /* Textbox Styling */
  .projcard-textbox {
    position: absolute;
    top: 7%;
    bottom: 7%;
    left: 430px;
    width: calc(100% - 470px);
    font-size: 17px;
    color: #483c32;
  }
  
  /* Bar and Gradient Backgrounds */
  .projcard-bar {
    left: -2px;
    width: 50px;
    height: 5px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #d5a021; /* Gold accent color */
    transition: width 0.2s ease;
  }
  
  .projcard:hover .projcard-bar {
    width: 70px;
  }
  
  /* Card Text */
  .projcard-title {
    font-family: 'Voces', 'Open Sans', arial, sans-serif;
    font-size: 24px;
    color: #483c32; /* Dark taupe text */
  }
  
  .projcard-subtitle {
    font-family: 'Voces', 'Open Sans', arial, sans-serif;
    color: #d5a021; /* Gold accent for subtitle */
  }
  
  /* Description Styling */
  .projcard-description {
    z-index: 10;
    font-size: 15px;
    color: #483c32;
    height: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Tag Box Styling */
  .projcard-tagbox {
    position: absolute;
    bottom: 3%;
    font-size: 14px;
    cursor: default;
    user-select: none;
    pointer-events: none;
  }
  
  .projcard-tag {
    display: inline-block;
    background: #483c32;
    color: #fdf7d9;
    border-radius: 3px 0 0 3px;
    line-height: 26px;
    padding: 0 10px 0 23px;
    position: relative;
    margin-right: 20px;
    cursor: default;
    user-select: none;
    transition: color 0.2s;
  }
  
  .projcard-tag::before {
    content: '';
    position: absolute;
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    height: 6px;
    left: 10px;
    width: 6px;
    top: 10px;
  }
  
  .projcard-tag::after {
    content: '';
    position: absolute;
    border-bottom: 13px solid transparent;
    border-left: 10px solid #d5a021;
    border-top: 13px solid transparent;
    right: -10px;
    top: 0;
  }
  