/* Main Container */
.container1 {
    display: flex; /* Use flexbox for the main container */
    max-width: 1200px; /* Set a maximum width for the container */
    margin: 0 auto; /* Center container horizontally */
    background-color: #fdf7d9; /* Match body background color */
    border: 2px solid #d5a021; /* Add a solid border with a specific color */
    border-radius: 10px; /* Round the corners of the container */
    overflow: hidden; /* Hide any overflow content */
}

/* Sidebar */
.sidebar {
    width: 20%; /* Set width of sidebar to 20% of the container */
    background-color: #483c32; /* Dark brown background for sidebar */
    padding: 20px; /* Add padding inside the sidebar */
    border-right: 2px solid #d5a021; /* Right border for separation from main content */
    display: flex; /* Use flexbox for sidebar layout */
    flex-direction: column; /* Arrange sidebar items in a column */
    height: 90vh; /* Set height to 85% of the viewport */
    box-sizing: border-box; /* Include padding and border in the width and height calculations */
}

/* Sidebar heading should remain fixed */
.sidebar h2 {
    color: #d5a021; /* Gold color for sidebar heading */
    margin-bottom: 20px; /* Space below the heading */
    font-size: 1.5rem; /* Font size for heading */
    text-align: center; /* Center the heading text */
    flex-shrink: 0; /* Prevent heading from shrinking */
}

/* Days list should scroll independently */
.days-list {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove padding from list */
    margin: 0; /* Remove margin from list */
    flex-grow: 1; /* Allow the list to take up remaining vertical space */
    overflow-y: hidden; /* Initially hide vertical overflow, controlled via JS */
    display: flex; /* Use flexbox for the list */
    flex-direction: column; /* Arrange list items in a column */
    align-items: center; /* Center items horizontally */
}

/* Style individual list items */
.days-list li {
    margin-bottom: 15px; /* Space below each list item */
    width: 100%; /* Full width for each list item */
}

/* Style the links within the list */
.days-list button1 {
    text-decoration: none; /* Remove underline from links */
    color: #fdf7d9; /* Light yellow color for link text */
    font-size: 1.1rem; /* Font size for links */
    font-weight: bold; /* Bold text for links */
    display: block; /* Make links block elements */
    position: relative; /* Set position for pseudo-elements */
    text-align: center; /* Center link text */
    padding: 10px 0; /* Add vertical padding for links */
    width: 100%; /* Full width for links */
}

/* Hover effect with yellow line animation */
.days-list button1::before {
    content: ""; /* Create a pseudo-element for the hover effect */
    position: absolute; /* Position it absolutely */
    bottom: 0; /* Align to the bottom of the link */
    left: 0; /* Align to the left of the link */
    width: 0; /* Start with zero width */
    height: 2px; /* Set height for the hover effect */
    background-color: #d5a021; /* Color for the hover line */
    transition: width 0.4s ease-in-out; /* Animate the width change */
}

.days-list button1:hover::before {
    width: 100%; /* Expand to full width on hover */
}

/* Active link color */
.days-list button1:hover,
.days-list button1.active {
    color: #d5a021; /* Change link color on hover or if active */
}

/* Ensure scrolling works well for mobile or narrow screens */
@media (max-width: 768px) { /* Media query for screens less than or equal to 768px */
    .sidebar {
        width: 100%; /* Set sidebar to full width */
    }

    .days-list {
        flex-direction: row; /* Arrange list items in a row */
        overflow-x: auto; /* Allow horizontal scrolling */
    }

    .days-list li {
        margin-right: 10px; /* Space between list items */
        margin-bottom: 0; /* Remove bottom margin when in a row */
    }
}

/* Main Content and Carousel Layout */
.main-content {
    display: flex; /* Use flexbox for main content layout */
    width: 80%; /* Set width to 80% of the container */
    padding: 20px; /* Add padding inside main content */
    background-color: #fdf7d9; /* Match the background color */
}

/* Content Section */
.content {
    width: 60%; /* Set width of content section to 60% */
    padding-right: 20px; /* Space on the right */
    border-right: 2px solid #d5a021; /* Right border for separation */
}

.content h2 {
    color: #483c32; /* Dark color for content headings */
    font-size: 2rem; /* Font size for content headings */
    margin-bottom: 15px; /* Space below the heading */
}

/* Timeline section for displaying events */
.timeline {
    position: relative; /* Set position for timeline items */
    padding-left: 30px; /* Add padding to the left for timeline style */
    animation: fadeIn 0.7s ease-in-out; /* Fade-in animation on load */
}

.timeline::before {
    content: ''; /* Create a pseudo-element for the timeline line */
    position: absolute; /* Position it absolutely */
    left: 0; /* Align to the left */
    top: 0; /* Align to the top */
    bottom: 0; /* Extend to the bottom */
    width: 2px; /* Width of the timeline line */
    background-color: #d5a021; /* Color of the timeline line */
}

.timeline-item {
    position: relative; /* Set position for timeline items */
    margin-bottom: 20px; /* Space below each timeline item */
}

.timeline-item::before {
    content: ''; /* Create a pseudo-element for the item marker */
    position: absolute; /* Position it absolutely */
    left: -34px; /* Position to the left of the item */
    top: 5px; /* Align vertically within the item */
    width: 10px; /* Width of the marker */
    height: 10px; /* Height of the marker */
    border-radius: 50%; /* Make the marker circular */
    background-color: #d5a021; /* Color of the marker */
}

/* Navigation buttons for the timeline */
.navigation {
    margin-top: 20px; /* Space above navigation */
    display: flex; /* Use flexbox for navigation layout */
    justify-content: space-between; /* Space items evenly */
}

/* Target only buttons inside the navigation container */
.navigation button {
    padding: 10px 20px; /* Padding inside buttons */
    background-color: #483c32; /* Dark background for buttons */
    color: #fdf7d9; /* White text color for buttons */
    border: none; /* Remove border from buttons */
    cursor: pointer; /* Change cursor on hover */
    font-size: 1rem; /* Font size for buttons */
    transition: background-color 0.3s ease; /* Smooth background color transition on hover */
    border-radius: 10px;
}

.navigation button:hover {
    background-color: #d5a021; /* Change background color on hover */
}


/* General Carousel Styles */
.carousel-section {
    width: 40%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel,
.carousel2 {
    position: relative;
    width: 100%;
}

/* Image Styling */
.carousel-image,
.carousel-image2 {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    overflow: hidden;
    border: 2px solid #d5a021;
    border-radius: 8px;
}

.carousel-image img,
.carousel-image2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

/* Navigation buttons for the carousel */
.carousel-navigation {
    position: absolute;
    bottom: 90px; /* Positioning 20px above the bottom edge */
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(0); /* Remove vertical centering */
}

.carousel-navigation .prev,
.carousel-navigation .next {
    background-color: rgba(58, 48, 40, 0.5);
    color: #fdf7d9;
    padding: 10px;
    cursor: pointer;
    font-size: 1.5rem;
}


/* Dots for carousel navigation */
.carousel-dots {
    text-align: center;
    margin-top: -8px;
}

.dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: rgba(58, 48, 40, 0.5);
    border-radius: 50%;
    cursor: pointer;
}

.dot.active {
    background-color: #d5a021;
}

/* Keyframe Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

